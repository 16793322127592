import React, {Component, Fragment} from 'react'
import {ApplicationInsights} from '@microsoft/applicationinsights-web'
import {ReactPlugin, withAITracking,} from '@microsoft/applicationinsights-react-js'

import {IsDev} from '../utils'

let reactPlugin = null
let appInsights = null

export default withAITracking(
    reactPlugin,
    class extends Component {
        componentDidMount() {
            if (!appInsights) {
                if (!IsDev()) {
                    this.initializeTelemetry()
                }
            }
        }

        initializeTelemetry = () => {
            if (!this.props.history) {
                throw new Error('Could not initialize Telemetry Service')
            }

            if (!this.props.instrumentationKey) {
                throw new Error('Instrumentation key not provided')
            }

            reactPlugin = new ReactPlugin()
            appInsights = new ApplicationInsights({
                config: {
                    instrumentationKey: this.props.instrumentationKey,
                    maxBatchInterval: 0,
                    disableFetchTracking: false,
                    extensions: [reactPlugin],
                    extensionConfig: {
                        [reactPlugin.identifier]: {
                            history: this.props.history
                        }
                    }
                }
            })
            appInsights.loadAppInsights()
        }

        render() {
            return <Fragment>{this.props.children}</Fragment>
        }
    }
)

export {appInsights}