// noinspection JSUnusedGlobalSymbols

import {message} from 'antd'
import React from 'react'

export const is_supported_video_format = (filename = '') =>
    ['mp4', 'webm', 'weba', 'ogv', 'mpg', 'mp2', 'mpeg', 'mpe', 'mpv', 'm4p', 'ogg', 'mpa'].indexOf((filename.split('.').pop() || '').toLowerCase()) >= 0

export function isPromise(value) {
    if (value !== null && typeof value === 'object') {
        return value.promise && typeof value.promise.then === 'function'
    }
}

export const isMac = () => navigator.platform.indexOf('Mac') > -1

export const isWindows = () => navigator.platform.indexOf('Win') > -1

export const delay = ms => new Promise(_ => setTimeout(_, ms))

export const toParams = params =>
    '?' +
    Object.keys(params)
        .map(param => `${param}=${encodeURIComponent(params[param])}`)
        .join('&')

export const prettyBytes = (size, nospace, one, places) => {
    const sizes = ['Bytes', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB']

    /**
     Pretty print a size from bytes
     @method pretty
     @param {Number} size The number to pretty print
     @param {Boolean} [nospace=false] Don't print a space
     @param {Boolean} [one=false] Only print one character
     @param {Number} [places=1] Number of decimal places to return
     */

    if (typeof nospace === 'object') {
        const opts = nospace
        nospace = opts.nospace
        one = opts.one
        places = opts.places || 1
    } else {
        places = places || 1
    }

    let mysize

    sizes.forEach((unit, id) => {
        if (one) {
            unit = unit.slice(0, 1)
        }
        const s = Math.pow(1024, id)
        let fixed
        if (size >= s) {
            fixed = String((size / s).toFixed(places))
            if (fixed.indexOf('.0') === fixed.length - 2) {
                fixed = fixed.slice(0, -2)
            }
            mysize = fixed + (nospace ? '' : ' ') + unit
        }
    })
    if (!mysize) {
        let unit = one ? sizes[0].slice(0, 1) : sizes[0]
        mysize = '0' + (nospace ? '' : ' ') + unit
    }
    return mysize
}

export const newGuid = () => {
    let s4 = () =>
        Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1)
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4()
}

export const parseError = e => {
    if (e.name === 'NetworkError') {
        return {title: 'There was a network error.', detail: 'Please check if you are connected to the internet.'}
    }

    let data = (e.response || {}).data || {}

    if (data.errors && Object.values(data.errors).length > 0) {
        data.detail = Object.values(data.errors)[0][0]
    }

    return {title: data.title || e.message || 'An unknown error occurred.', detail: data.detail}
}

export const showError = e => {
    debugger
    let {title, detail} = parseError(e)
    message.error(
        <>
            <span>{title}</span>
            <br/>
            <span>{detail}</span>
        </>,
        30
    )
}

export const showSuccess = data => {
    var {title, message: msg, detail} = data
    message.success(
        <>
            <span>{title || msg || 'Request completed successfully.'}</span>
            <br/>
            <span>{detail}</span>
        </>,
        20
    )
}

export const setData = data => {
    data = JSON.parse(JSON.stringify(data || {}))
    window.localStorage.setItem('a', data.token)
    delete data.token
    window.localStorage.setItem('user', JSON.stringify(data))
    //i18n.changeLanguage(((data.setting || {}).culture || {}).name)
}

export const IsDev = () => {
    try {
        return window.location.hostname === 'localhost' || '_self' in React.createElement('div') || process?.env?.NODE_ENV === 'development';
    } catch {
        return false;
    }
}

export const StartOfWeek = relativeDate => {
    const date = new Date(relativeDate || new Date())
    const firstDayOfWeekNumber = new Intl.Locale(navigator.language).weekInfo.firstDay

    // return (new Date(date.setDate(date.getDate() - date.getDay() + (date.getDay() == 0 ? -6 : 1))));

    let diff = date.getDay() - firstDayOfWeekNumber
    if (diff < 0) diff += 7
    return new Date(new Date(date).setDate(date.getDate() - diff))
}

export const EndOfWeek = relativeDate => {
    const date = relativeDate || new Date()
    const startOfWeek = StartOfWeek(date)
    return new Date(startOfWeek.setDate(startOfWeek.getDate() + 6))
}

export const AddTimeSpan = (relativeDate, timeSpan) => {
    let date = new Date(relativeDate || new Date())
    let time = timeSpan.split(':')

    date.setHours(time[0])
    date.setMinutes(time[1])
    date.setSeconds(time[2])

    return date
}