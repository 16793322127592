import React, {Component} from 'react'
import {SeverityLevel} from '@microsoft/applicationinsights-web'
import {appInsights} from './AppInsights'

export default class extends Component {

    state = {hasError: false}

    static getDerivedStateFromError() {
        return {hasError: true}
    }

    componentDidCatch(error, errorInfo) {
        console.log(error, errorInfo)
        appInsights?.trackException({
            error: error,
            exception: error,
            severityLevel: SeverityLevel.Error,
            properties: {...errorInfo},
        })
    }

    render() {
        if (this.state.hasError) {
            return <h1>Something went wrong.</h1>
        }

        return this.props.children
    }
}