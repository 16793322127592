import React from 'react'
import {Spinner} from 'reactstrap'
import './_css.sass'

export default ({message}) => {
    return (
        <div className="busy">
            {message || 'Loading ...'}
            <br/>
            <Spinner color="success"/>
        </div>
    )
}