import React, {lazy, Suspense} from 'react'
import {createRoot} from "react-dom/client"

import {createBrowserHistory} from 'history'
import {BrowserRouter, Route, Routes} from 'react-router-dom'
import {Talkr} from 'talkr'
import * as serviceWorker from './serviceWorker'

import ErrorBoundary from './components/ErrorBoundary'
import Busy from './components/states/busy'
import AppInsightsProvider from './components/AppInsights'
import GuardedRoute from './views/auth/GuardedRoute'

import en from './components/i18n/en'
import fr from './components/i18n/fr'

import 'dayjs/locale/en'
import 'dayjs/locale/fr'

import './views/main.sass'

const AuthLayout = lazy(() => import('./views/auth'))
const PortalLayout = lazy(() => import('./views/portal'))
const AdminLayout = lazy(() => import('./views/admin'))
const LandingView = lazy(() => import('./views/landing'))

const browserHistory = createBrowserHistory()

var AppFunc = () =>
    <React.Fragment>
        <Talkr languages={{en, fr}} defaultLanguage='en' detectBrowserLanguage>
            <AppInsightsProvider instrumentationKey='b0865c1e-e272-40f9-9ec7-226abde94015' history={browserHistory}>
                <ErrorBoundary>
                    <Suspense fallback={<Busy/>}>
                        <BrowserRouter>
                            <Routes>
                                <Route index element={<LandingView/>}/>
                                <Route path='/auth/*' element={<AuthLayout/>}/>
                                <Route
                                    path='/portal/*'
                                    element={
                                        <GuardedRoute>
                                            <PortalLayout/>
                                        </GuardedRoute>
                                    }
                                />
                                <Route
                                    path='/admin/*'
                                    element={
                                        <GuardedRoute>
                                            <AdminLayout/>
                                        </GuardedRoute>
                                    }
                                />
                            </Routes>
                        </BrowserRouter>
                    </Suspense>
                </ErrorBoundary>
            </AppInsightsProvider>
        </Talkr>
    </React.Fragment>

const rootElement = document.getElementById("root")
if (rootElement.hasChildNodes()) {
    try {
        debugger

        // hydrateRoot(rootElement, AppFunc(), {
        //     onRecoverableError: (error, errorInfo) => {
        //         debugger
        //         console.error(
        //             'Caught error',
        //             error,
        //             error.cause,
        //             errorInfo.componentStack
        //         );
        //     },
        //     onCaughtError: (error, errorInfo) => {
        //         debugger
        //         console.error(
        //             'Uncaught error',
        //             error,
        //             errorInfo.componentStack
        //         );
        //     },
        //     onUncaughtError: (error, errorInfo) => {
        //         debugger
        //         console.error(
        //             'Uncaught error',
        //             error,
        //             errorInfo.componentStack
        //         );
        //     }
        // })
    } catch (e) {
        debugger
        console.error(e)
        rootElement.innerHTML = ''
        createRoot(rootElement).render(AppFunc())
    }
} else {
    createRoot(rootElement).render(AppFunc())
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()